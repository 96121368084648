.App {
  text-align: center;
}
.Language {
  height:80%;
  width:100%;
  display:flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
}
.FoodOrDrink {
  display:flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
}
.Food {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  align-items:center;
}
.FoodType {
  display:flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
}
.DrinkType {
  display:flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
}
.Drinks {
  display:flex;
  flex-direction:column;
  flex-wrap: wrap;
  align-items:center;
}



